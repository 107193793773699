<template>
  <div>
    <Navbar />

    <section class="px-6 py-10 md:px-12 md:py-20 flex-1">
      <div class="flex items-center mb-5">
        <label
          for="session-name"
          class="leading-none text-sm font-semibold mr-12"
          >Nome Serata:</label
        >
        <input
          name="session-name"
          id="session-name"
          class="border-none bg-slate-800 rounded focus:ring-2 focus:ring-sky-500 outline-none"
          type="text"
          v-model="sessionData.name"
        />
      </div>

      <div class="flex items-center mb-5">
        <label
          for="public-name"
          class="leading-none text-sm font-semibold mr-12"
          >Nome pubblico:</label
        >
        <input
          name="public-name"
          id="public-name"
          class="border-none bg-slate-800 rounded focus:ring-2 focus:ring-sky-500 outline-none"
          type="text"
          v-model="sessionData.publicName"
        />
      </div>

      <div class="flex items-center mb-5">
        <label
          for="session-date"
          class="leading-none text-sm font-semibold mr-12"
          >Data inizio serata:</label
        >
        <Datepicker v-model="sessionData.date"></Datepicker>
      </div>

      <div class="flex items-center mb-5">
        <label
          for="session-date"
          class="leading-none text-sm font-semibold mr-12"
          >Data fine serata:</label
        >
        <Datepicker v-model="sessionData.dateEnd"></Datepicker>
      </div>

      <!-- <div class="flex items-center mb-5">
        <label for="join-mode" class="leading-none text-sm font-semibold mr-12"
          >Modalità di partecipazione:</label
        >
        <select
          name="join-mode"
          id="join-mode"
          class="border-none bg-slate-800 rounded focus:ring-2 focus:ring-sky-500 outline-none"
          v-model="sessionData.joinMode"
        >
          <option value="private">Registrazione obbligatoria</option>
          <option value="guests_only">Senza registrazione</option>
          <option value="public">Entrambi</option>
        </select>
      </div> -->

      <div class="flex items-center mb-5">
        <label for="max-pick-count" class="leading-none text-sm font-semibold"
          >Numero massimo di canzoni scelte per utente:</label
        >
        <input
          name="max-pick-count"
          id="max-pick-count"
          class="border-none bg-slate-800 rounded focus:ring-2 focus:ring-sky-500 outline-none"
          type="number"
          min="0"
          step="1"
          v-model="sessionData.maxPickCount"
          @change="submittedInvalidForm = false"
        />
      </div>

      <div class="flex items-center mb-5">
        <label for="delay" class="leading-none text-sm font-semibold"
          >Delay scelte canzoni (in secondi):</label
        >
        <input
          name="delay"
          id="delay"
          class="border-none bg-slate-800 rounded focus:ring-2 focus:ring-sky-500 outline-none"
          type="number"
          min="0"
          step="60"
          v-model="sessionData.delay"
          @change="submittedInvalidForm = false"
        />
      </div>

      <div class="flex items-center mb-5">
        <label for="cal-rate" class="leading-none text-sm font-semibold"
          >Difficoltà:</label
        >
        <select
          v-model="sessionData.calRate"
          @change="submittedInvalidForm = false"
          name="cal-rate"
          id="cal-rate"
          class="border-none bg-slate-800 rounded focus:ring-2 focus:ring-sky-500 outline-none"
        >
          <option :value="150">Soft (150 cal/h)</option>
          <option :value="300">Normal (300 cal/h)</option>
          <option :value="450">High (450 cal/h)</option>
        </select>
      </div>

      <hr class="my-12" />

      <div>
        <h3 class="text-lg lg:text-2xl">Definizione campi extra</h3>
        <table>
          <thead>
            <tr>
              <th>Nome</th>
              <th>Chiave</th>
              <th>Tipo</th>
              <th>Obbligatorio</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(def, i) in sessionData.extraDef">
              <td>
                <input
                  v-model="def.name"
                  type="text"
                  :name="`extraDefs[${i}][name]`"
                  class="border-none bg-slate-800 rounded focus:ring-2 focus:ring-sky-500 outline-none"
                />
              </td>
              <td>
                <input
                  v-model="def.key"
                  type="text"
                  :name="`extraDefs[${i}][key]`"
                  class="border-none bg-slate-800 rounded focus:ring-2 focus:ring-sky-500 outline-none"
                />
              </td>
              <td>
                <select
                  v-model="def.type"
                  :name="`extraDefs[${i}][type]`"
                  class="border-none bg-slate-800 rounded focus:ring-2 focus:ring-sky-500 outline-none"
                >
                  <option value="text">Testuale</option>
                  <option value="number">Numerico</option>
                </select>
              </td>
              <td>
                <input
                  v-model="def.required"
                  type="checkbox"
                  :name="`extraDefs[${i}][required]`"
                  class="border-none bg-slate-800 rounded focus:ring-2 focus:ring-sky-500 outline-none"
                />
              </td>
              <td>
                <button
                  @click="sessionData.extraDef.splice(i, 1)"
                  class="flex flex-col items-center gap-1"
                >
                  <ArrowNarrowRightIcon
                    class="rounded-full border border-white border-opacity-10 lg:hidden w-8 h-8 p-1 hover:bg-sky-500 transition"
                  />
                  <TrashIcon
                    class="w-5 h-5 lg:text-sky-500 lg:hover:text-red-500 transition"
                  ></TrashIcon>
                </button>
              </td>
            </tr>
          </tbody>
          <button
            @click="addExtraDef"
            class="btn btn--red disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Aggiungi
          </button>
        </table>
      </div>
      <hr class="my-12" />
      <div class="">
        <div class="">
          <form class="" id="sessionCreate" @submit.prevent="onSubmit">
            <div class="flex justify-between">
              <h3 class="text-lg lg:text-2xl">
                Aggiungi playlist alla serata:
              </h3>

              <div>
                <input
                  type="checkbox"
                  name="selectAll"
                  class=""
                  v-model="selectAll"
                />
                <label
                  for="selectAll"
                  class="text-sky-500 text-xs uppercase font-semibold tracking-wide hover:text-red-500 transition duration-300"
                  >Seleziona tutto</label
                >
              </div>
            </div>

            <div class="grid md:grid-cols-2 2xl:grid-cols-4 gap-4 mt-2 mb-5">
              <div
                class="bg-slate-800 rounded flex gap-2 lg:gap-4 items-center p-2"
                v-for="playlist in playlists"
                :key="playlist.id"
              >
                <div>
                  <input
                    class="w-7 h-7 rounded focus-within:bg-sky-500 focus:bg-sky-500 checked:bg-sky-500"
                    :value="playlist.id"
                    type="checkbox"
                    name="session-playlist"
                    :id="`playlist-${playlist.id}`"
                    v-model="sessionPlaylists"
                  />
                </div>
                <div class="flex gap-2">
                  <img
                    :src="playlist.image"
                    :alt="playlist.name"
                    class="w-20 h-20"
                  />
                  <div>
                    <h3 class="font-semibold capitalize">
                      {{ playlist.name }}
                    </h3>
                    <p class="text-sm opacity-60">{{ playlist.description }}</p>
                  </div>
                </div>
              </div>
            </div>

            <button
              class="btn btn--red disabled:opacity-50 disabled:cursor-not-allowed"
              v-on:click="submit"
            >
              Salva
            </button>
          </form>

          <div
            v-if="success"
            :class="{ 'bg-green-100 border-green-200 text-green-500': success }"
            class="mt-2 p-1 border rounded-sm text-xs"
          >
            {{ success }}
          </div>
          <div v-else>{{ error }}</div>

          <div v-if="submittedInvalidForm">
            <div v-for="error in v$.$silentErrors" :key="error">
              <p
                class="form-errors bg-red-100 border border-red-200 text-xs text-red-500 mt-2 p-1 rounded-sm"
              >
                {{ error.$message }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <Footer />
  </div>
</template>

<script>
import { getClient } from "@/includes/api";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { pick } from "lodash";

import Footer from "@/components/Footer.vue";
import Navbar from "@/components/Navbar.vue";
import Datepicker from "@vuepic/vue-datepicker";

import { ArrowNarrowRightIcon, TrashIcon } from "@heroicons/vue/solid";

export default {
  props: ["id", "name"],
  components: { Footer, Navbar, Datepicker, ArrowNarrowRightIcon, TrashIcon },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      sessionData: {},
      sessionPlaylists: [],
      playlists: [],
      submittedInvalidForm: false,
      success: false,
      formDisabled: true,
      error: "",
      message: "",
      selectAll: false,
    };
  },
  async mounted() {
    try {
      let id = this.$props.id;
      let apiClient = getClient();

      const session = await apiClient.getSession(id);
      if (session !== null) {
        this.sessionData = session;
        this.sessionData.date = new Date(this.sessionData.date);
        this.sessionData.dateEnd = new Date(this.sessionData.dateEnd);
      }

      let playlists = await apiClient.getPlaylists();
      if (Array.isArray(playlists)) {
        this.playlists = playlists;
      }

      let sessionPlaylists = await apiClient.getSessionPlaylists(id);
      if (Array.isArray(sessionPlaylists)) {
        let playlistsIds = sessionPlaylists.map((playlist) => playlist.id);
        this.sessionPlaylists = playlistsIds;
      }
    } catch (error) {
      console.log(error.message);
      this.error = error.message;
    }
  },
  methods: {
    async editSession() {
      try {
        let apiClient = getClient();

        const payload = pick(this.sessionData, [
          "name",
          "publicName",
          "code",
          "maxPickCount",
          "delay",
          "extraDef",
          "calRate",
          "joinMode",
        ]);
        payload.date = this.sessionData.date.toISOString();
        payload.dateEnd = this.sessionData.dateEnd.toISOString();
        const session = await apiClient.updateSession(this.id, payload);
        session.date = new Date(session.date);
        session.dateEnd = new Date(session.dateEnd);
        this.sessionData = session;

        let playlistsAdded = await apiClient.addPlaylistsToSession(
          this.$props.id,
          this.sessionPlaylists
        );

        if (!playlistsAdded) {
          console.log(err.message);
          this.error = err.message;
        } else {
          console.log("Playlist added");
          this.success = "Playlist aggiornate!";
        }
      } catch (error) {
        console.log(error.message);
        this.error = error.message;
      }
    },
    pushPlaylist(id) {
      this.sessionPlaylists.push(id);
    },
    async submit() {
      this.submittedInvalidForm = false;
      const isValid = await this.v$.$validate();
      if (!isValid) {
        this.submittedInvalidForm = true;
        return false;
      }

      await this.editSession();

      this.success = true;
      return true;
    },
    validateName(name) {
      return name.length > 0;
    },
    addExtraDef() {
      this.sessionData.extraDef.push({
        name: "",
        key: "",
        type: "text",
        required: false,
      });
    },
  },
  watch: {
    selectAll() {
      if (this.selectAll) {
        this.sessionPlaylists = this.playlists.map((p) => p.id);
      } else {
        this.sessionPlaylists = [];
      }
    },
  },
  validations() {
    return {
      sessionData: {
        name: {
          required,
          name_validation: {
            $validator: this.validateName,
            $message:
              "Attenzione: il nome può contenere solo lettere, trattini e spazi",
          },
        },
        date: { required },
        dateEnd: { required },
      },
    };
  },
};
</script>

<style scoped>
button:disabled {
  cursor: not-allowed;
}
</style>
